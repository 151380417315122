<template>
  <b-card title="Role">
    <b-modal
      title="Delete Selected"
      v-model="promptDeleteSelected"
      class="export-options"
      @cancel="promptDeleteSelected = false"
      @ok="deleteMultiple"
      ok-title="Delete"
      @close="promptDeleteSelected = false"
      :active.sync="promptDeleteSelected"
    >
      <p>Are you sure that you want to permanently delete selected roles?</p>
    </b-modal>

    <b-modal
      title="Delete Role"
      v-model="promptDelete"
      class="export-options"
      @cancel="cancelDelete"
      @close="cancelDelete"
      @ok="confirmDelete"
      ok-title="Delete"
      :active.sync="promptDelete"
    >
      <p>Are you sure that you want to permanently delete selected role?</p>
    </b-modal>

    <!--<div style="margin-bottom:10px; display:flex;">
       <b-button @click="promptDeleteSelected=true" color="danger" v-if="selected.length !== 0 && permission.delete">Delete Selected</b-button> 
    </div>-->

    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button
            to="/settings/role/add"
            style="margin-right:15px;"
            variant="success"
            v-if="permission.add"
            >Add Role</b-button
          >
          <b-button
            style="margin-right:15px;"
            variant="secondary"
            @click="getReport"
            v-if="permission.add"
            >Report</b-button
          >
        </b-col>
        <b-col cols="4" />
        <b-col cols="4">
          <span class="spin" v-if="showSpinner"></span>
          <b-form-input
            v-model="filter"
            @input="debounceSearch"
            placeholder="Type to Search"
            type="search"
            style="margin-left:10px"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            responsive
            :fields="fields"
            show-empty
            selectable
            select-mode="multi"
            v-model="selected"
            @filtered="onFiltered"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :items="roles"
          >
            <template #cell(name)="row">
              <b-link
                :to="{ name: 'role-view', params: { id: row.item.id } }"
                >{{ row.item.name }}</b-link
              >
            </template>

            <template #cell(createdAtFormatted)="row">
              {{ row.item.createdAtFormatted }}
            </template>

            <template #cell(updatedAtFormatted)="row">
              {{ row.item.updatedAtFormatted }}
            </template>

            <template #cell(createdBy)="row">
              {{ row.item.createdBy?row.item.createdBy:'-' }}
            </template>
            
            <template #cell(updatedBy)="row">
              {{ row.item.updatedBy?row.item.updatedBy:'-' }}
            </template>

            <template #cell(action)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="
                    (row.item.name !== 'Super Admin' ||
                      row.item.level !== 'Super Admin') &&
                      permission.edit
                  "
                >
                  <b-button
                    variant="outline-primary"
                    :to="{ name: 'role-edit', params: { id: row.item.id } }"
                    class="mr-1"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="
                    (row.item.name !== 'Super Admin' ||
                      row.item.level !== 'Super Admin') &&
                      permission.delete
                  "
                >
                  <b-button
                    variant="danger"
                    @click="deleteRole(row.item.id)"
                    class="mr-1"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <!-- <template slot-scope="{data}">
              <b-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <b-td>
                  {{ indextr + 1 }}
                </b-td>
                <b-td :data="tr.name">
                  <router-link :to="{name:'role-view', params: { id: tr.id } }">{{tr.name}}</router-link>
                </b-td>
                <b-td :data="tr.level">
                  {{tr.level}}
                </b-td>
                <b-td :data="tr.createdAtFormatted">
                  {{tr.createdAtFormatted}}
                </b-td>
                <b-td :data="tr.updatedAtFormatted">
                  {{tr.updatedAtFormatted}}
                </b-td>
                <b-td v-if="permission.edit || permission.delete" style="width:185px">
                  <router-link
                    v-if="(tr.name !== 'Super Admin' || tr.level !== 'Super Admin') && permission.edit"
                    tag="b-button"
                    style="margin-right:15px;"
                    :to="{name:'role-edit', params: { id: tr.id } }">
                    <feather-icon icon="EditIcon" svgClasses="h-4 w-4"/>
                  </router-link>
                  <b-button
                    v-if="(tr.name !== 'Super Admin' || tr.level !== 'Super Admin') && permission.delete"
                    color="danger"
                    @click="deleteRole(tr.id)">
                    <feather-icon icon="Trash2Icon" svgClasses="h-4 w-4"/>
                  </b-button>
                </b-td>
              </b-tr>
            </template> -->
          </b-table>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="dataSearch"
        :per-page="perPage"
        style="float: right"
      />
    </b-col>
  </b-card>
</template>

<script>
import { dateFormat, userAccess } from "@/utils/utils.js";
import * as XLSX from "xlsx"
export default {
  data() {
    return {
      daikinHeader: require('@/assets/images/drcc/Daikin_Header.png'),
      fields: [
        { key: "name", text: "Name", filteralbe: true, sortable: true },
        { key: "level", text: "Level", filteralbe: true, sortable: true },
        {
          key: "createdAtFormatted",
          label: "Created",
          text: "Created",
          filteralbe: true,
          sortable: true,
        },
        {
          key: "updatedAtFormatted",
          label: "Updated",
          filteralbe: true,
          sortable: true,
        },
        { key: "createdBy", text: "Created By", filteralbe: true, sortable: true },
        { key: "updatedBy", text: "Updated By", filteralbe: true, sortable: true },
        { key: "action", text: "Action" },
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      filterOn: [],
      showSpinner: false,
      search: "",
      debounce: null,
      selected: [],
      descriptionItems: [5, 10, 25, 50, 100],
      promptDeleteSelected: false,
      deleteCounter: 0,
      promptDelete: false,
      deleteId: "",
    };
  },
  methods: {
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.filter = event.target.value;
      }, 600);
    },

    deleteRole(id) {
      this.deleteId = id;
      this.promptDelete = true;
    },
    cancelDelete() {
      this.promptDelete = false;
      this.selected = [];
    },
    confirmDelete() {
      this.$store
        .dispatch("role/deleteRole", { id: this.deleteId })
        .then(() => {
          this.$bvToast.toast("Successfully Delete Role", {
            variant: "success",
            title: "Successfully Delete Role",
          });
          this.selected = [];
        })
        .catch((err) => {
          console.log({ err });
          this.$bvToast.toast(err.response.data.errors[0].message, {
            variant: "danger",
            title: "Delete Failed",
          });
        });
      this.promptDelete = false;
    },

    deleteMultiple() {
      if (this.deleteCounter === 0) {
        this.temp = this.selected;
      }
      if (this.deleteCounter < this.temp.length) {
        setTimeout(() => {
          this.deleteId = this.temp[this.deleteCounter].id;

          this.deleteCounter++;
          this.confirmDelete();
          this.deleteMultiple();
        }, 50);
      } else {
        this.deleteCounter = 0;
        this.promptDeleteSelected = false;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getReport(){
      this.$store.dispatch("role/getReport").then((data)=>{
        console.info('getreport ',data)
        this.generateExcel(data.groupedData)
      }).catch((err) => console.log(err));  
    },

    generateExcel(data){
      // Create a new workbook
      console.info('data ',data);
      const workbook=  XLSX.utils.book_new();;
      
      var current;
      var worksheet;
      var rows;
      var mergeList
      

      for (var x = 0;x<data.length;x++){
        var current= data[x];
          // Create a new worksheet
        worksheet = XLSX.utils.aoa_to_sheet([]);
        
        
        rows=[];
        mergeList=[];
      
        //title
        rows.push([{ v: 'Daikin User Permission Report'}])
        mergeList.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } })
      
        //space
        rows.push([{}]) //row 1
        rows.push([{}]) //row 2
      
        //header
        rows.push([{ v: 'Name' ,s: { font: { bold: true }, alignment: { horizontal: 'center' } }, t: 's'},{ v: 'Role'},{ v: 'Module'},{ v: 'Permission'}]) //row 3
      
        var curNameRow=4;
        var curModuleRow=4;
        var namePermissionCounter=0;
        var modulePermissionCounter=0;
        var moduleKeys;
      
      
        var curData;
        var userList;
        var userIndex;
        var curPermission;
        var permissionIndex="0";
        for(var i =0;i<current.levelList.length;i++){
          curData=current.levelList[i]
          userList = curData.userList.map((x)=>{
            return x.name
          });
          userIndex = 0;
          namePermissionCounter=0;
          modulePermissionCounter=0;
          moduleKeys = Object.keys(curData.permission)
          
          for (let moduleKeyIndex in moduleKeys){
            
            if(curData.permission[moduleKeys[moduleKeyIndex]].length==0){
              continue;
            }
            for (permissionIndex in curData.permission[moduleKeys[moduleKeyIndex]]){
              
              curPermission = curData.permission[moduleKeys[moduleKeyIndex]][permissionIndex]
      
              let permTemp ="";
              if(curPermission.list==undefined){
                permTemp=JSON.stringify(curPermission)
              }else{
                Object.keys(curPermission.list).map((x)=>{
                  if(curPermission.list[x]!=undefined){
                    permTemp+=""+x+", "
                  }
                  
                })
              }

              //clean last coma
              permTemp = permTemp.substring(0,permTemp.length-2)
              


              rows.push([
                {v:(userIndex>=userList.length)?"":userList[userIndex]},
                {v:(userIndex>0)?"":curData.level},
                {v:moduleKeys[moduleKeyIndex]},
                {v:curPermission.name+" ("+permTemp+")"}
              ])
              userIndex+=1;
            }
            //if(permissionIndex==0){
              //console.info(curData.name,'only one permission '+moduleKeys[moduleKeyIndex]+' ',permissionIndex)
            //}
            let addModuleRow = parseInt(permissionIndex)      
            mergeList.push({ s: { r: curModuleRow, c: 2 }, e: { r: curModuleRow+addModuleRow, c: 2 } })
            //}
            modulePermissionCounter+=1+addModuleRow;
            curModuleRow+=1+addModuleRow;
          }
          mergeList.push({ s: { r: curNameRow+userList.length, c: 0 }, e: { r: curNameRow+modulePermissionCounter-1, c: 0 } })
          mergeList.push({ s: { r: curNameRow+1, c: 1 }, e: { r: curNameRow+modulePermissionCounter-1, c: 1 } })
      
          curNameRow+=modulePermissionCounter
        }
        console.info(mergeList)
      
        // Add data to the worksheet
        XLSX.utils.sheet_add_aoa(worksheet, rows);
      
        // Set column spans
        worksheet['!merges'] = mergeList;
      
        var wscols = [
          {wch:10},
          {wch:10},
          {wch:15},
          {wch:20}
        ];
        
        worksheet['!cols'] = wscols;
      
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, current.name);
      }
      // Write the workbook to a file
    
      XLSX.writeFile(workbook, 'role-report-'+new Date().getTime()+'.xlsx');
    
    
    }    
  },
  computed: {
    rowsRole() {
      return this.roles.length;
    },
    permission() {
      return userAccess("Role", "settings_menu");
      // var getPermission = JSON.parse(this.$session.get('permission'));
      // console.log('permission log',getPermission)

      // if (getPermission.allAccess) {
      //   return {
      //     add:true,
      //     edit:true,
      //     delete:true
      //   }
      // }
      // else {
      //   return getPermission.settings_menu.filter(el => {
      //     return el.name === 'Role';
      //   })[0]
      // }
    },
    user() {
      return this.$store.getters["auth/getActiveUser"];
    },
    roles() {
      var getRoles = this.$store.getters["role/getRole"]
        ? this.$store.getters["role/getRole"].map((el) => {
            return {
              ...el,
              createdAtFormatted: dateFormat(el.createdAt),
              updatedAtFormatted: dateFormat(el.updatedAt),
            };
          })
        : [];
      // getRoles = getRoles.filter(doc => {
      //   var searchedObject = false;
      //   for (let value of Object.values(doc)) {
      //     var stringifyValue = JSON.stringify(value).toLowerCase();
      //     if (stringifyValue.includes(this.search.toLowerCase())) {
      //       searchedObject = true;
      //       break;
      //     }
      //   }
      //   return doc.database === this.user.database && searchedObject;
      // })
      return getRoles;
    },
    dataSearch() {
      let paginationLength;
      paginationLength = this.roles.filter((el) => {
        return el.name.includes(this.filter);
      });
      if (this.filter !== null) {
        return paginationLength.length;
      } else {
        return this.roles.length;
      }
    },
  },
  created() {
    document.title = "Role | RSP";
  },
  mounted() {
    this.$store.dispatch("auth/fetchUser").catch((err) => console.log(err));
    this.$store.dispatch("role/fetchRole").catch((err) => console.log(err));
  },
};
</script>

<style>
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>
